import { initAmplitude } from "../utils/amplitude/init"

export const setUserIdAmplitude = user => {
  if (window.amplitude !== undefined) {
    if (user?.amplitudeUserId) {
      window.amplitude.setUserId(user?.amplitudeUserId)
    }
    return
  }

  initAmplitude()
}

const mapAmplitudeProduct = (product, overrides = {}) => {
  const basePrice = product?.adobeRecord?.everyDayLowPrice || product.everyDayLowPrice
  const netPrice = product?.adobeRecord?.productDiscountPrice || product.productDiscountPrice
  const productDesc = product?.adobeRecord?.productDescription || product.productDescription
  const productId = product.productPartNumber

  const breadCrumbsList = product?.itemCategoriesDTO?.breadCrumbsDTOList[0]?.breadCrumbsList || []
  const Dept = breadCrumbsList[0]?.name
  const Category = breadCrumbsList[1]?.name
  const SubCategory = breadCrumbsList[2]?.name

  return {
    ProductID: productId,
    ProductName: product?.adobeRecord?.productName || product.productName,
    BasePrice: basePrice,
    NetPrice: netPrice,
    Units: 1,
    BOGO: product?.adobeRecord?.bogo || product.bogo,
    Dept,
    Category,
    SubCategory,
    IsSuggestion: false,
    ...(productDesc && { ProductIDDesc: `${productId}-${productDesc}` }),
    ...overrides
  }
}

const mapAmplitudeCartProduct = (product, overrides = {}) => {
  return {
    PageName: new URL(window.location.href).pathname,
    ...mapAmplitudeProduct(product, overrides),
    ...overrides
  }
}

const mapAmplitudeUser = (userInfo, overrides = {}) => {
  return {
    RewardsMember: null,
    EasyRefillSubscriber: null,
    PaymentMethodSaved: null,
    PrimaryAddressSaved: null,
    TotalOrdersLTD: null,
    TotalSalesLTD: null,
    FirstOrderDate: null,
    LastOrderDate: null,
    AccountCreationDate: null,
    SourceCode: null,
    ...overrides
  }
}

export default function useAmplitude() {
  return {
    mapAmplitudeProduct,
    mapAmplitudeUser,
    mapAmplitudeCartProduct,
    trackEvent: (eventName, eventProperties) => {
      window.amplitude.track(eventName, eventProperties)
    }
  }
}
