import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { CarouselProduct } from '../CarouselProduct/CarouselProduct'
import { Headline } from '../_styled/Headline'
import { LoadAnimation } from '../LoadAnimation/LoadAnimation'
import { useMediaQuery } from 'react-responsive'
import { Chevron } from '../_svgs/Chevron/Chevron'
import { AnimateFromNone } from '../AnimateFromNone/AnimateFromNone'
import { utagLink } from '../../tealium'
import { CircleChevron } from '../_svgs/CircleChevron/CircleChevron'
import { gql, useClient } from 'urql'
import { showNotificationWithTimeout } from '../../actions/notificationActions'
import { useDispatch } from 'react-redux'
import useAmplitude from '~/hooks/useAmplitude'

export const CarouselContainer = ({
  endpoint,
  maxProducts = 12,
  carouselType,
  index = 0,
  updateCart = false,
  atcProdId,
  showHeader = true,
  prodPerPage = 4,
  useProductCards = true,
  containerDivStyles,
  parentDivStyles,
  carouselContainerStyles,
  useGraphQL = true,
  gqlType,
  productID
}) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const carouselProductContainer = useRef()
  const ref = useRef()
  const isInitialRender = useRef(true)
  const [title, setTitle] = useState('Loading...')
  const [MKSActionId, setMKSActionId] = useState()
  const [constructorRecommendationData, setConstructorRecommendationData] = useState({
    resultId: "",
    request: {
      pod_id: "",
      num_results: 0
    }
  })
  const [stateProductPartNumber, setStateProductPartNumber] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(stateProductPartNumber.length)
  const [productsPerPage, setProductsPerPage] = useState(prodPerPage)
  const isSM = useMediaQuery({ maxWidth: 768, minWidth: 576 })
  const isMD = useMediaQuery({ maxWidth: 992, minWidth: 768 })
  const isL = useMediaQuery({ minWidth: 992 })
  const pcIsL = useMediaQuery({ minWidth: 1200 })
  const pcIsMD = useMediaQuery({ minWidth: 768, maxWidth: 1290 })
  const client = useClient()
  const [styles, setStyles] = useState(css`
    width: 100%;
  `)

  const { trackEvent: trackAmplitudeEvent, mapAmplitudeProduct } = useAmplitude()

  const handleAmplitudeEvent = (product) => {
    trackAmplitudeEvent('carousel product clicked', {
      ProductDetails: [mapAmplitudeProduct(product, { IsSuggestion: true })],
      HeadlineText: title,
      PodID: constructorRecommendationData.request['pod_id'],
    })
  }

  const handleAmplitudeCarouselViewed = () => {
    const HeadlineText = ref.current?.dataset?.carouselTitle
    const PodID = ref.current?.dataset?.carouselPodId

    if (HeadlineText !== 'Loading...') {
      trackAmplitudeEvent('carousel viewed', {
        HeadlineText,
        PodID
      })
    }
  }

  useEffect(() => {
    if (isL && !useProductCards) {
      setStyles(css`width: 25%`)
      setNumberOfPages(Math.ceil(stateProductPartNumber.length / 4))
      setProductsPerPage(4)
      if (stateProductPartNumber.length < 4) {
        setStyles(css`width: 33.3%`)
      }
    } else if ((!useProductCards && isMD) || (useProductCards && pcIsL)) {
      setStyles(css`width: 33.3%`)
      setNumberOfPages(Math.ceil(stateProductPartNumber.length / 3))
      setProductsPerPage(3)
    } else if ((!useProductCards && isSM) || (useProductCards && pcIsMD)) {
      setStyles(css`width: 50%`)
      setNumberOfPages(Math.ceil(stateProductPartNumber.length / 2))
      setProductsPerPage(2)
    } else if (useProductCards) {
      setStyles(css`width: 100%`)
      setProductsPerPage(3)
      setNumberOfPages(1)
    } else {
      setStyles(css`width: 100%`)
      setProductsPerPage(1)
      setNumberOfPages(stateProductPartNumber.length)
    }
    setPage(0)
  }, [stateProductPartNumber.length, isMD, isL, isSM, pcIsL, pcIsMD])

  const getItems = () => {
    //Accepted gqlType strings are: cart, emptySearch, cartmodal, homepage, productdetail
    if (useGraphQL && gqlType) {
      const CAROUSEL_STRING = gql`
        query ($carouselType: String!, $productID: String) {
          getRecommendation(type: $carouselType, id: $productID) {
            recommendationResultList {
              title
              actionId
              originalRecommendationRequest
              result {
                adobeRecord {
                  constructorOptions
                  productDiscountPrice
                  discount
                  productDescription
                  productPartNumber
                  masterGroupItem
                  newWebItem
                  bogo
                  longURL
                  productName
                  productVendor
                  productDetails
                  percentDiscount
                  rating
                  numReviews
                  everyDayLowPrice
                  tooLowToShow
                  statusUnavailable
                  flags
                  rewards2x
                  rewards3x
                  rewards4x
                }
              }
            }
          }
        }`
      client.query(CAROUSEL_STRING, {
        carouselType: gqlType,
        productID: productID
      }).toPromise().then(result => {
        if (result?.error?.networkError) {
          dispatch(showNotificationWithTimeout({
            time: 5000,
            background: 'warn',
            notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
            active: true
          }))
        } else {
          setTitle(result.data.getRecommendation.recommendationResultList[index].title)
          setMKSActionId(result.data.getRecommendation.recommendationResultList[index].actionId)
          setStateProductPartNumber(result.data.getRecommendation.recommendationResultList[index].result.slice(0, maxProducts))

          const recommendationList = result.data?.getRecommendation?.recommendationResultList;

          if (recommendationList?.[index]?.originalRecommendationRequest !== null) {
            try {
              const originalRecommendationRequest = JSON.parse(recommendationList[index].originalRecommendationRequest);
              setConstructorRecommendationData(originalRecommendationRequest);
            } catch (e) {
              console.log(e);
              setConstructorRecommendationData({
                resultId: "",
                request: {
                  pod_id: "",
                  num_results: 0
                }
              });
            }
          }

          const event = new window.Event('xhr-loaded')
          document.dispatchEvent(event)
          let tealiumObj = {
            'tealium_event': 'carousel_loaded'
          }
          if (atcProdId) {
            tealiumObj['atc_modal_prod_id'] = atcProdId
          }
          utagLink(tealiumObj)
        }
      }).then(() => {
        handleAmplitudeCarouselViewed()
      })
    } else {
      axios.get(endpoint)
        .then((response) => {
          setTitle(response.data.recommendationResultList[index].title)

          const recommendationResult = response.data.recommendationResultList[index];

          if (recommendationResult && recommendationResult.originalRecommendationRequest) {
            try {
              const constructorRecommendationData = JSON.parse(recommendationResult.originalRecommendationRequest);
              if (constructorRecommendationData !== undefined) {
                setConstructorRecommendationData(constructorRecommendationData);
              }
            } catch (e) {
              console.error(e);
              setConstructorRecommendationData({
                resultId: "",
                request: {
                  pod_id: "",
                  num_results: 0
                }
              });
            }
          }

          setMKSActionId(response.data.recommendationResultList[index].actionId)
          setStateProductPartNumber(response.data.recommendationResultList[index].result.slice(0, maxProducts))
          const event = new window.Event('xhr-loaded')
          document.dispatchEvent(event)
          let tealiumObj = {
            'tealium_event': 'carousel_loaded'
          }
          if (atcProdId) {
            tealiumObj['atc_modal_prod_id'] = atcProdId
          }
          utagLink(tealiumObj)
        }).then(() => {
        handleAmplitudeCarouselViewed()
      })
    }
  }

  const buildProductSections = (numberPerSection) => {
    let productSections = []
    for (let x = 0; x < stateProductPartNumber.length; x += numberPerSection) {
      const carouselProducts = []
      for (let z = 0; z < numberPerSection; z++) {
        if (stateProductPartNumber[z + x]) {
          const constructorOptions = stateProductPartNumber[z + x].adobeRecord?.constructorOptions;
          if (constructorOptions!== null && !constructorOptions.isObject) {
            try {
              stateProductPartNumber[z + x].adobeRecord.constructorOptions = JSON.parse(constructorOptions);
            } catch (e) {
              console.log(e);
            }
          }
        }

        const carouselProduct = typeof stateProductPartNumber[z + x]?.adobeRecord !== 'undefined' && numberPerSection > 0 ? (
          <CarouselProduct
            focus={(z + x) / numberPerSection === page}
            product={stateProductPartNumber[z + x].adobeRecord}
            styles={useProductCards ? css`@media (max-width: 768px) {width: 100%; margin-bottom: 5rem; flex: 1 1 100%;} @media (min-width: 769px) {${styles}}` : styles}
            index={x + z}
            actionId={MKSActionId}
            carouselName={title}
            classes='react-carousel_Product'
            updateCart={updateCart}
            useProductCards={useProductCards}
            onClick={() => handleAmplitudeEvent(stateProductPartNumber[z]?.adobeRecord)}
          />
        )
          : null
        if(carouselProduct) {
          carouselProducts.push(carouselProduct)
        }

      }
      productSections.push(
        <AnimateFromNone styles={useProductCards ? css`@media(max-width: 768px) { flex-wrap: wrap; }` : ''} visible={x / numberPerSection === page}>
          {carouselProducts}
        </AnimateFromNone>
      )
    }
    return productSections
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      if(carouselProductContainer.current.querySelector('#productFocus')) {
        carouselProductContainer.current.querySelector('#productFocus').focus()
      }

    }
  }, [page])

  useEffect(() => {
    let observer
    let didCancel = false
    if (ref && stateProductPartNumber.length === 0) {
      if (typeof IntersectionObserver !== 'undefined') {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                // do ajax and display contents
                getItems()
                observer.unobserve(ref.current)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '0%'
          }
        )
        observer.observe(ref.current)
      } else {
        // Old browsers fallback
        getItems()
      }
    }
    return () => {
      didCancel = true
      // on component cleanup, we remove the listener
      if (observer && observer.unobserve) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  const forwardHandler = () => {
    if (page !== numberOfPages - 1) {
      setPage(page + 1)
    }
  }

  const backHandler = () => {
    if (page !== 0) {
      setPage(page - 1)
    }
  }
  return (
      <ContainerDiv data-carousel-title={title} data-carousel-pod-id={constructorRecommendationData.request['pod_id']} styles={containerDivStyles} ref={ref}>
        {showHeader &&
          <Headline
            styles={css`
              @media (min-width: ${props => props.theme.breakpoints.md}) {
                font-size: 1.7rem;
                text-align: center;
              }
            `}>
            {title}
          </Headline>
        }
        <CarouselProductParentDiv styles={parentDivStyles}>
          <CarouselContainerDiv
            styles={carouselContainerStyles}
            data-testid='carouselContainer'
            data-id={carouselType}
            data-title={title}
            className='real-react-carousel'
            data-cnstrc-recommendations
            data-cnstrc-recommendations-pod-id={constructorRecommendationData.request['pod_id']}
            data-cnstrc-num-results={constructorRecommendationData.request['num_results']}
            data-cnstrc-result-id={constructorRecommendationData.resultId}
            ref={carouselProductContainer}
          >
            {buildProductSections(productsPerPage)}
            {stateProductPartNumber.length === 0 &&
              <LoadAnimation />
            }
          </CarouselContainerDiv>
        </CarouselProductParentDiv>
        {numberOfPages > 1 &&
          <ChevronDiv>
            <ChevronButton
              disabled={page === 0}
              aria-label='previous slide'
              data-testid='back'
              title='back'
              onClick={backHandler}
            >
              {useProductCards ?
                <CircleChevron disabled={page === 0}/>
                :
                <Chevron color={page === 0 ? '#545454' :'#2F711C' }/>
              }
            </ChevronButton>
            <ChevronButton
              disabled={page === numberOfPages - 1}
              aria-label='next slide'
              data-testid='forward'
              title='forward'
              onClick={forwardHandler}
            >
              {useProductCards ?
                <CircleChevron disabled={page === numberOfPages - 1} point='right'/>
                :
                <Chevron point='right' color={page === numberOfPages - 1 ? '#545454' : '#2F711C'}/>
              }
            </ChevronButton>
          </ChevronDiv>
        }
      </ContainerDiv>
  )
}
const ContainerDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2rem;
  @media print {
    display: none;
  }
  ${props => props.styles};
`

const CarouselContainerDiv = styled.div`
  display: flex;
  min-height: 30rem;
  align-items: center;
  justify-content: center;
  ${props => props.styles};
`

const CarouselProductParentDiv = styled.div`
  overflow: hidden;
  margin-top: 1.5rem;
  ${props => props.styles};
`

const ChevronDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ChevronButton = styled.button`
  background-color: transparent;
  width: 6rem;
  margin: 0 2rem;
  border: none;
  cursor: pointer;
  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    svg path {
      fill: ${props => props.disabled ? '#545454' : '#2F711C'};
    }
    &:hover {
      cursor: ${props => props.disabled ? 'default' : 'pointer'};
      svg path {
        fill: ${props => props.disabled ? '#545454' : '#2F711C'};
      }
    }
  }
`
